import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TestroomModel } from '../models/TestroomModel';
import { TopButton, TopButtonTypography } from '../TestroomTemplate';

import { CancelButton } from 'components/General/Buttons/CancelButton';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import { ClassroomContext, ClientLabel } from '../contexts/ClassroomContextProvider';
import { useAssignDevice } from '../hooks/useAssignDevice';
import { AssignLabelRequest } from '../hooks/classroomHooks/requestAssignMultipleLabel';

import DragIndicator from '@mui/icons-material/DragIndicator';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

type StudentItemProps = {
    id: string;
    studentName: string;
    studentNumber: string;
    profilePicURL: string;
};

const colorAry = ['#FF8282', '#FFB31F', '#FFDE33', '#82FF9D', '#4BF4FF', '#98C1FF', '#F582FF'];

type Props = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    testroomData: TestroomModel;
};

export const TestroomAssignDevice = (props: Props) => {
    const { t } = useTranslation();
    const { socket, studentClientList } = useContext(ClassroomContext);
    const { studentData, loading, onDragEnd, assignMany, assigningDevices, clearAssignOne, clearAssignMany, confirmAssign, startAssign, completedStudents } = useAssignDevice({
        setOpen: props.setOpen,
    });

    // useEffect(() => {
    //     console.log('classroom: list:', studentClientList);
    // }, [studentClientList]);

    // NOTE we only have idx for source (under the same droppable)
    // and id for destination (different droppable, index == 0)

    const clearAllAssigns = () => {
        const socketIds = studentClientList.filter((client) => client.clientNumber !== -1).map((client) => client.socketId);
        clearAssignMany(socketIds);
    };

    const autoAssign = () => {
        const studentProfileIds = studentData?.studentProfiles?.map((profile) => profile.id);
        const clientIds = studentClientList?.map((client) => client.studentProfileId);

        const notAssignedYetStudentProfileIds = studentProfileIds?.filter((id) => !clientIds.includes(id));
        const availableStudentData = studentData?.studentProfiles?.filter((profile) => notAssignedYetStudentProfileIds?.includes(profile.id));

        if (!availableStudentData || availableStudentData.length <= 0) {
            return;
        }

        const deviceList = studentClientList.filter((client) => {
            return client.studentProfileId === '';
        });

        let assignIdx = 0;
        const labels: AssignLabelRequest[] = [];
        for (const device of deviceList) {
            // skip the teachers
            if (device.clientNumber === -1) {
                continue;
            }

            labels.push({
                studentName: availableStudentData[assignIdx].familyName + '' + availableStudentData[assignIdx].givenName,
                socketId: device.socketId,
                studentProfileId: availableStudentData[assignIdx].id,
                studentNumber: availableStudentData[assignIdx].studentNumber,
                profilePicURL: availableStudentData[assignIdx].profilePic?.url || '',
                action: 'change',
            });

            assignIdx++;
        }

        assignMany({ socket: socket, labels: labels });
    };

    const StudentItem = (props: StudentItemProps & { index: number; clientList: ClientLabel[] }) => {
        const assignedInClientList = studentClientList.find((item) => {
            return item.studentProfileId === props.id;
        });

        const isAssigning = Object.keys(assigningDevices).length > 0;
        const isAssigned = assignedInClientList != null;

        const avatarPath = props.profilePicURL;

        const isCompletedStudent = completedStudents.find((student) => student.studentId === props.id);

        return (
            <Draggable key={'studentItem_' + props.id} draggableId={props.id} index={props.index} isDragDisabled={isAssigned || isAssigning}>
                {(provided, snapshot) => (
                    <>
                        <Box
                            sx={{
                                display: isAssigned ? 'none' : 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '218px',
                                borderRadius: '4px',
                                backgroundColor: isCompletedStudent ? '#DCEDC8' : 'white',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            {isCompletedStudent ? (
                                <TaskAltIcon sx={{ color: '#0000008A' }} />
                            ) : (
                                <Avatar
                                    src={avatarPath}
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        marginRight: '8px',
                                    }}
                                />
                            )}

                            <Typography
                                noWrap
                                sx={{
                                    textAlign: 'center',
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                }}>
                                {`${props.studentName} (${props.studentNumber})`}
                            </Typography>
                            <DragIndicator sx={{}} />
                        </Box>
                    </>
                )}
            </Draggable>
        );
    };

    const DeviceItem = (
        props: ClientLabel & {
            index: number;
        },
    ) => {
        const isAssigned = props.studentProfileId !== '';
        const isLoading = assigningDevices[props.socketId];

        const backgroundColor = isAssigned ? colorAry[props.clientNumber % colorAry.length] : 'black';
        const textColor = isAssigned ? 'black' : 'white';

        const avatarPath = isAssigned ? props.profilePicURL : undefined;

        return (
            <Grid item xs={2} key={'deviceItem_' + props.socketId}>
                <Droppable droppableId={'deviceId_' + props.socketId}>
                    {(provided, snapshot) => (
                        <Box
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            sx={{
                                borderRadius: '4px',
                                backgroundColor: snapshot.isDraggingOver ? '#9E9E9E' : backgroundColor,
                                border: snapshot.isDraggingOver ? '2px dashed #F05023' : '2px solid #9E9E9E',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}>
                                {avatarPath && (
                                    <Avatar
                                        src={avatarPath}
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            marginBottom: '4px',
                                        }}
                                    />
                                )}

                                {isLoading && (
                                    <Typography
                                        sx={{
                                            color: textColor,
                                            width: '100%',
                                            textAlign: 'center',
                                        }}>
                                        {'Loading...'}
                                    </Typography>
                                )}

                                {!isLoading && !isAssigned && (
                                    <Typography
                                        sx={{
                                            color: 'white',
                                            width: '100%',
                                            textAlign: 'center',
                                        }}>
                                        {props.deviceLabel}
                                    </Typography>
                                )}
                                {!isLoading && isAssigned && (
                                    <>
                                        <Typography
                                            sx={{
                                                color: textColor,
                                                width: '100%',
                                                textAlign: 'center',
                                            }}>
                                            {props.studentName}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: textColor,
                                                width: '100%',
                                                textAlign: 'center',
                                            }}>
                                            {props.studentNumber}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            {!isLoading && isAssigned && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 4,
                                        left: 4,
                                        backgroundColor: 'black',
                                        borderRadius: '4px',
                                        paddingLeft: '4px',
                                        paddingRight: '4px',
                                    }}>
                                    <Typography
                                        sx={{
                                            color: 'white',
                                        }}>
                                        {props.deviceLabel}
                                    </Typography>
                                </Box>
                            )}
                            {/* make box aspect ratio 3:2 */}
                            <Box sx={{ paddingBottom: '66.67%' }} />

                            {isAssigned && (
                                <IconButton
                                    onClick={() => {
                                        clearAssignOne(props.socketId);
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        p: '4px',
                                        color: '#212121',
                                    }}>
                                    {props.isGuest && <CloseIcon sx={{ fontSize: 20 }} />}
                                </IconButton>
                            )}

                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </Grid>
        );
    };

    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const [updateStudentList, setUpdateStudentList] = useState<boolean>(false);
    const [updateClientList, setUpdateClientList] = useState<boolean>(false);

    // only update studentList / client list when is not dragging
    useEffect(() => {
        if (!isDragging) {
            setUpdateStudentList(!updateStudentList);
        }
    }, [studentClientList, studentData?.studentProfiles, isDragging]);

    useEffect(() => {
        if (!isDragging) {
            setUpdateClientList(!updateClientList);
        }
    }, [studentClientList, isDragging]);

    const StudentList = useMemo(() => {
        return studentData?.studentProfiles?.map((item, index) => (
            <StudentItem
                id={item.id}
                studentName={item.familyName + '' + item.givenName}
                studentNumber={item.studentNumber}
                profilePicURL={item.profilePic?.url || ''}
                index={index}
                clientList={studentClientList}
            />
        ));
    }, [updateStudentList, assigningDevices, completedStudents]);

    const ClientList = useMemo(() => {
        return studentClientList
            .filter((v) => v.clientNumber !== -1)
            .map((item, index) => (
                <DeviceItem
                    key={item.socketId}
                    studentProfileId={item.studentProfileId}
                    studentName={item.studentName}
                    studentNumber={item.studentNumber}
                    profilePicURL={item.profilePicURL}
                    clientNumber={item.clientNumber}
                    role={item.role}
                    socketId={item.socketId}
                    deviceLabel={item.deviceLabel}
                    index={index}
                    isGuest={item.isGuest}
                />
            ));
    }, [updateClientList, assigningDevices]);

    useEffect(() => {
        let disabled = false;

        let hasClient = false;
        let hasAssignedClient = false;

        for (let client of studentClientList) {
            // is a student client (client number != -1)
            if (client.clientNumber != -1) {
                hasClient = true;
                // studentProfileId is not empty
                if (client.studentProfileId?.length != 0) {
                    hasAssignedClient = true;
                    break;
                }
            }
        }

        if (!hasClient || (hasClient && !hasAssignedClient)) {
            disabled = true;
        }

        if (!disabled) {
            // is assigning a student to a device
            for (let key in assigningDevices) {
                if (assigningDevices[key]) {
                    disabled = true;
                    break;
                }
            }
        }

        setSubmitDisabled(disabled);
    }, [studentClientList, assigningDevices]);

    const isAssigning = Object.keys(assigningDevices).length > 0;

    return (
        <>
            <TopButton onClick={startAssign}>
                <TopButtonTypography>{t('assign.reassign')}</TopButtonTypography>
            </TopButton>

            <Dialog fullWidth maxWidth='xl' disableEscapeKeyDown={true} open={props.open} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{t('assign.assign-student')}</DialogTitle>
                <DialogContent>
                    <Stack>
                        <DialogContentText
                            sx={{
                                color: '#000000',
                            }}>
                            {t('assign.please-enter-class-code', {
                                code: props.testroomData?.otp,
                            })}
                        </DialogContentText>
                        <DialogContentText
                            id='alert-dialog-description'
                            sx={{
                                fontSize: '12px',
                                fontWeight: 'regular',
                                color: '#533d18',
                            }}>
                            {t('assign.assign-student-description')}
                        </DialogContentText>
                    </Stack>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: '32px',
                            marginBottom: '16px',
                        }}>
                        <CancelButton
                            disabled={isAssigning}
                            onClick={() => {
                                clearAllAssigns();
                            }}
                            sx={{
                                fontSize: '12px',
                            }}>
                            {t('assign.assign-clear')}
                        </CancelButton>
                        <SubmitButton
                            disabled={isAssigning}
                            onClick={() => {
                                autoAssign();
                            }}
                            sx={{
                                fontSize: '12px',
                            }}>
                            {t('assign.auto-assign')}
                        </SubmitButton>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                            maxHeight: 'calc(100vh - 350px)',
                            overflow: 'auto',
                        }}>
                        <DragDropContext
                            onDragEnd={(result) => {
                                onDragEnd(result);
                                setIsDragging(false);
                            }}
                            onBeforeCapture={() => {
                                setIsDragging(true);
                            }}>
                            <Box
                                sx={{
                                    backgroundColor: '#F3EDE5',
                                    minWidth: '234px',
                                    paddingLeft: '8px',
                                    paddingRight: '8px',
                                }}>
                                <Stack
                                    sx={{
                                        flexGrow: 1,
                                        height: 'calc(100vh - 350px)',
                                        overflow: 'auto',
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            margin: '8px 0',
                                        }}>
                                        <span>{t('assign.student-list')}</span>
                                    </Box>

                                    <Droppable
                                        droppableId='student'
                                        direction='vertical'
                                        // isDropDisabled={true}
                                    >
                                        {(provided) => (
                                            <Stack
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                spacing={1}
                                                sx={
                                                    {
                                                        // flexGrow: 1,
                                                    }
                                                }>
                                                {StudentList}
                                                {provided.placeholder}
                                            </Stack>
                                        )}
                                    </Droppable>
                                </Stack>
                            </Box>
                            <Box
                                sx={{
                                    backgroundColor: '#F3EDE5',
                                    flexGrow: 1,
                                    minWidth: '1024px',
                                    height: 'calc(100vh - 350px)',
                                    overflow: 'auto',
                                }}>
                                <Grid container spacing={2}>
                                    {ClientList}
                                </Grid>
                            </Box>
                        </DragDropContext>
                    </Box>
                </DialogContent>

                <DialogActions
                    sx={{
                        justifyContent: 'flex-end',
                        paddingRight: '24px',
                        paddingBottom: '24px',
                    }}>
                    <SubmitButton onClick={confirmAssign} autoFocus disabled={submitDisabled}>
                        {t('assign.start-lesson')}
                    </SubmitButton>
                </DialogActions>
            </Dialog>

            <PopupCircularProgress isOpened={loading} />
        </>
    );
};
