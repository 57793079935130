import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useCallback, useContext } from 'react';
import { Questions, TestroomModel, TestroomWithQuestions } from 'pages/testroom/models/TestroomModel';

export const GET_TESTROOM = gql`
    query Testroom($where: TestroomWhereUniqueInput!) {
        testroom(where: $where) {
            id
            completedStudents
            testroomName
            startTime
            lessonVersion {
                id
                lessonQuestions
                slides {
                    id
                    questionCount
                }
            }
        }
    }
`;

export type ModelQueryType = {
    testroom: TestroomModel & Questions;
};

type QueryVariableType = {
    classroomId: string;
};

export const useClassroomQuery = () => {
    const auth = useContext(UserAuthContext);
    const [getTestRoomIds, { data: testRoomIdsData, loading: resultsLoading }] = useLazyQuery(GET_TESTROOM);

    const getClassroom = useCallback(
        async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
            try {
                const result = await getTestRoomIds({
                    variables: {
                        where: {
                            id: variables.classroomId,
                        },
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                });

                if (!result?.data) {
                    throw new TTError(ERROR_TYPE.NO_DATA);
                }

                return result?.data;
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    auth.accessDenied(errorMsg);
                }
                throw new TTError(errorMsg);
            }
        },
        [getTestRoomIds, auth],
    ); // Added dependencies for useCallback

    return { getClassroom, data: testRoomIdsData, resultsLoading };
};
